import React from 'react';
import { graphql } from 'gatsby';
import { PageMeta } from '@components/pageMeta';
import Layout from '@components/layout';
import SimplePortableText from '@components/sanity/simplePortableText';

const SimplePage = ({ data: { page } }) => {
  const { seo, title, copy } = page || {};
  return (
    <Layout>
      <PageMeta {...seo} />
      <div className='px-gutter md:mx-gutter mt-32 md:mt-64 mb-48 md:mb-80'>
        {title && (
          <h1 className='text-black blockH2 text-center w-full mb-20 md:mb-40 uppercase'>
            {title}
          </h1>
        )}
        <SimplePortableText text={copy} />
      </div>
    </Layout>
  );
};

export default SimplePage;

export const simpePageQuery = graphql`
  query standardSimplePageQuery($slug: String!) {
    page: sanitySimplePage(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      title
      copy: _rawText(resolveReferences: { maxDepth: 100 })
    }
  }
`;
